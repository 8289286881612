
import { Options, setup, Vue } from 'vue-class-component'
import ProductDetail from '@/components/dialogs/ProductDetail.vue'
import ErroDialog from '@/components/dialogs/ErrorDialog.vue'
import ConfirmDialog from 'primevue/confirmdialog'
import { inject, onMounted } from '@vue/runtime-core'
import { useConfirm } from 'primevue/useconfirm'

import router from './router'
import { useStore } from 'vuex'
import { Actions } from './config/store.config'
import appconfigService from './services/appconfig/appconfig.service'
import { paymentUtil } from './utils/payment/payment-provider'
import { useRoute } from 'vue-router'

@Options({
  components: {
    ProductDetail,
    ConfirmDialog,
    ErroDialog
  }
})
export default class App extends Vue {
  context = setup(() => {
    const store = useStore()
    const route = useRoute()
    const confirm = useConfirm()
    const flavor = inject<string>('FLAVOR')

    onMounted(async () => {
      import(`@/assets/flavors/${flavor}/styles/bundle.scss`)
      const appconfig = await appconfigService.get()
      const timerSeconds = +(
        appconfig.DataConfig?.constants?.constantDefaultKioskResetTime ?? 90
      )

      const body = document.querySelector('body')
      body?.addEventListener('contextmenu', (ev) => {
        ev.preventDefault()
      })

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let timer: any = null
      const reset = () => {
        store.dispatch(Actions.HIDE_ALL_DIALOG)
        store.dispatch(Actions.HIDE_ALL_ERRORS)

        confirm.close()
        router.push('/')
      }

      const sessionWarning = () => {
        const excludedRoutes = [
          'home',
          'payment',
          'refund'
        ]

        if (excludedRoutes.includes(route.name as string)) return

        // store.dispatch(Actions.HIDE_ALL_DIALOG)
        // store.dispatch(Actions.HIDE_ALL_ERRORS)
        confirm.close()

        const warningTimer = setTimeout(() => {
          reset()
          setTimer()
        }, timerSeconds * 1000)

        confirm.require({
          header: '¿Estas ahí?',
          message:
            'Tu sesión expirará pronto. Por favor selecciona una de las siguientes opciones',
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'Continuar',
          rejectClass: 'btn-clean',
          rejectLabel: 'Salir',
          accept: () => {
            clearTimeout(warningTimer)
            setTimer()
          },
          reject: () => {
            clearTimeout(warningTimer)
            reset()
            setTimer()
          }
        })
      }

      const setTimer = () => {
        clearTimeout(timer)
        timer = null

        timer = setTimeout(sessionWarning, timerSeconds * 1000)
      }

      setTimer()
      body?.addEventListener('click', () => {
        setTimer()
      })

      if (!paymentUtil.initOnStart) {
        return
      }

      paymentUtil
        .init()
        .then((data) => {
          console.log(data)
        })
        .catch((err) => {
          confirm.require({
            message: err,
            header: 'PINPAD',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Aceptar',
            rejectClass: 'btn-clean none',
            accept: () => {
              window.location.reload()
            },
            reject: () => {
              window.location.reload()
            }
          })
        })
    })

    return {}
  })
}

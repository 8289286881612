/* eslint-disable @typescript-eslint/no-explicit-any */
import { KJUR, stob64, hextorstr } from 'jsrsasign'
import * as qz from 'qz-tray'
import { printLanguageUtil } from '../print-language/print-language-provider'

export class Printer {
  private printerName: string

  constructor (name: string) {
    this.printerName = name
  }

  public async getConfig (): Promise<any> {
    try {
      qz.security.setCertificatePromise((resolve: (text: string) => void) => {
        resolve(
          '-----BEGIN CERTIFICATE-----\n' +
            'MIIEDTCCAvWgAwIBAgIUE6jitdxuaxr1xPSKuNW0X5YrMh8wDQYJKoZIhvcNAQEL\n' +
            'BQAwgZQxCzAJBgNVBAYTAk1YMQ0wCwYDVQQIDARDRE1YMQ0wCwYDVQQHDARDRE1Y\n' +
            'MRQwEgYDVQQKDAtBRE9NSUNJTC5JTzELMAkGA1UECwwCSVQxIzAhBgNVBAMMGmtp\n' +
            'b3Njby1ob290ZXJzLmFkb21pY2lsLmlvMR8wHQYJKoZIhvcNAQkBFhBob2xhQGFk\n' +
            'b21pY2lsLmlvMCAXDTIyMDYyMDIwMDgyOVoYDzIwNTMxMjEzMjAwODI5WjCBlDEL\n' +
            'MAkGA1UEBhMCTVgxDTALBgNVBAgMBENETVgxDTALBgNVBAcMBENETVgxFDASBgNV\n' +
            'BAoMC0FET01JQ0lMLklPMQswCQYDVQQLDAJJVDEjMCEGA1UEAwwaa2lvc2NvLWhv\n' +
            'b3RlcnMuYWRvbWljaWwuaW8xHzAdBgkqhkiG9w0BCQEWEGhvbGFAYWRvbWljaWwu\n' +
            'aW8wggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQC5j2TG3D464NnJRNeo\n' +
            'rIYPFL9dHuG3/sITQ4WWq4IihMtqzTKWGvzKU3IzyOPkur35FEdjfDoAjyM0+W/n\n' +
            'YhYPtiOBYkJ/CZaDIlUXEErTqsHPQP5TFJxqXaKFbYQgkCaQAdBoMpAQI51HP8eU\n' +
            'txeqVNMdYRwOFEhuQ9UMvkOOklTKUpZIzGWtCgTJvGzqM345vawwalR57Atr33V9\n' +
            'aF2SmJXojwXWtFmWo2J2BGKVsOTedOxtxv0FC04Z7Ybyhq/OYpcXaCbtDXRExJGK\n' +
            'acDt5PakJ4BFJAkRRGvibpGlawZOXKmBbp/Lk9R+MRm9kAd7v9oIgJ5MRPIE/wXj\n' +
            'DYwnAgMBAAGjUzBRMB0GA1UdDgQWBBSk31+8XJE20Vy+A6L5HCu9ZYOGRTAfBgNV\n' +
            'HSMEGDAWgBSk31+8XJE20Vy+A6L5HCu9ZYOGRTAPBgNVHRMBAf8EBTADAQH/MA0G\n' +
            'CSqGSIb3DQEBCwUAA4IBAQBoyJiKcE9+KNHiuP2n48ETKtsGAuKF6DTRnHXyX2DZ\n' +
            'byk9Of/tDvRPmxj8ytJR2tH2HtLLQoGtRlfsAVaf7TUartyOZvkVAzXC0wmJCwsI\n' +
            'Kf34IVpxopExGPxFsVQ5uHUo5rWnMVMi/qBXfExDeJS8lNgVSuQtX8K0Uv0kkfIp\n' +
            'TQ/MGFAn+xH2KgquzutWQj4J0xNPL4X9VPTuNyhpanna0yY6LzRXhz1NusD46b+k\n' +
            '//BrQLoq7Tq07hMGd1dC9PJz6muKasdJBWd6jbD4JolqTIeuFKvbGapd+KAfW7hV\n' +
            'aO5vb83jNSpYhQCXWChgWdPXBNZpCruEQ3EH2x58JL1w\n' +
            '-----END CERTIFICATE-----'
        )
      })

      qz.security.setSignaturePromise((toSign: string) => {
        const privateKey =
          '-----BEGIN PRIVATE KEY-----\n' +
          'MIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQC5j2TG3D464NnJ\n' +
          'RNeorIYPFL9dHuG3/sITQ4WWq4IihMtqzTKWGvzKU3IzyOPkur35FEdjfDoAjyM0\n' +
          '+W/nYhYPtiOBYkJ/CZaDIlUXEErTqsHPQP5TFJxqXaKFbYQgkCaQAdBoMpAQI51H\n' +
          'P8eUtxeqVNMdYRwOFEhuQ9UMvkOOklTKUpZIzGWtCgTJvGzqM345vawwalR57Atr\n' +
          '33V9aF2SmJXojwXWtFmWo2J2BGKVsOTedOxtxv0FC04Z7Ybyhq/OYpcXaCbtDXRE\n' +
          'xJGKacDt5PakJ4BFJAkRRGvibpGlawZOXKmBbp/Lk9R+MRm9kAd7v9oIgJ5MRPIE\n' +
          '/wXjDYwnAgMBAAECggEBAKlMrTPKqnRpxgqeZu0cS0vjMB20GVkYdNXCm1W2sYrx\n' +
          'Ae81vNu7T70BmYap2VNNFhr1Z5TbOMhd0E967aB8m4hGM+rOTcE+bDbE0kGyubKZ\n' +
          'by67K899VKCvQaLfItfa7XQiU5Vd3Jb9B3iENoH28v6TWfLKMSCfXN5NwUpZJaYj\n' +
          'SRuAoGzT9T+3xQn0o+5CCSql1CZfFrRK43EshyCwIJrzTMNySQ8fwE78msReeko9\n' +
          'xar51hsh7b2xBA1zFHBAu1EdNdY0faEz257rLzvvpY8nFybWLb4C2U96Ozb6WBg8\n' +
          '+oa+tXei3Xl0E3Jm/8PNdklSbEu4vbkXPJ2RJWfYo6ECgYEA7to56pKvpDuPYDh5\n' +
          'ciViUkyNyT1fOQP1r0PwlWB8OLYKvAnyavizuT9h8L7LRZTKfnVSw6h/SAIO21D8\n' +
          'sNni46VsnG/Kq6SaTO+gnxRWNPrClK84GJgbzAwGGzfdFWra1ThRwQwBBB/3iKBq\n' +
          'rEEk/fiCMquRPIZsscjcr0wmmFkCgYEAxuG6r78riNqDhOdTuF6amF7exLJHNFjD\n' +
          '4kG1AdhNy+Gu/6Uo7chcZ3SYoYjOyXrDuKttoGV66JHKyR73N02hK6tcRiVzoK2C\n' +
          'ZnzrX1t1qR6awZggaDKKLkP3pwiL0hoWueKvxiNGoDLcXg9gifznvGBPd0P6lZhf\n' +
          'XQ/qzgSouH8CgYAgC+WDZITuWxNkjI820m87rYvEeViCVNas2ke4MwbXsQ0Fp6v/\n' +
          '0DiE3U0tuNubDViR2PnuyHezWJtXr6kHrWQ0uOg2oQUnPGF7exco4NikKRTcUBoN\n' +
          'qrLoEKDtG/Ssv/Z2byUA6rRkMknK6gMF84OcE7CcTcqhvUNuIw4A8w2mmQKBgQCE\n' +
          'V0z9Cb8UcCO4Wp6RPxKsdeTTCrbrFG40rvid46vREbp7jxGvOPaUOTgftYO0+qYm\n' +
          'ypxGcYDaJbnFH3/uIxAumPbsDpvkvdDaFiIh8CBZi237iRl3fLoKk1vGoJ86DfY1\n' +
          'd6zzsiGbFH0jciQRbmNeLNB0Up360cwThzX+JYeITQKBgQCOFhHMFUdzvuTa7tLW\n' +
          'wDQW5mde+FAKpC83YM1a+7APboTrxePSbCykPQ1BlbiarvB27110AMfK7HTyigUr\n' +
          '6HpP3/I9mlPePKRmZmNoKAv9NOMXeHDYEzUsGddEqfJEQdwSIiZqT0oTIyFeQrVX\n' +
          'R+Omj20DjsnuJYRwv+IfZ0yAWw==\n' +
          '-----END PRIVATE KEY-----'

        return (
          resolve: (text: string) => void,
          reject: (text: unknown) => void
        ) => {
          try {
            const sig = new KJUR.crypto.Signature({ alg: 'SHA1withRSA' })
            sig.init(privateKey) // signer's certificate
            sig.updateString(toSign)
            const hex = sig.sign()

            resolve(stob64(hextorstr(hex)))
          } catch (err) {
            console.error(err)
            reject(err)
          }
        }
      })

      const config = await qz.websocket
        .connect()
        .then(() => {
          return qz.printers.find(this.printerName)
        })
        .then((found: string) => {
          return qz.configs.create(found)
        })

      return config
    } catch (err) {
      return Promise.reject(err)
    }
  }

  public async print (config: any, dataString: string): Promise<any> {
    const data = printLanguageUtil.getQzPrintData(dataString)

    await qz
      .print(config, data)
      .then(() => {
        return qz.websocket.disconnect()
      })
      .catch((err: any) => {
        qz.websocket.disconnect()
        return Promise.reject(err)
      })
  }
}

const config = JSON.parse(localStorage.getItem('PRINTER_CONFIG') || '{}')
export const printerService = new Printer(config?.name ?? 'POS-80C')

import {
  PaymentInputModel,
  PaymentResponseModel,
  RefundInputModel,
  RefundResponseModel
} from './models/payment.model'
import { FiservUtil } from './providers/fiserv.util'
import { MitUtil } from './providers/mit.util'

export enum PaymentProviderType {
  MIT = 'mit',
  FISERV = 'fiserv'
}

export interface PaymentProviderData {
  type: PaymentProviderType
}

export interface PaymentProvider {
  get initOnStart(): boolean
  get requiresInteractiveCancel(): boolean

  init(): Promise<object>
  executePayment(data: PaymentInputModel): Promise<PaymentResponseModel>
  cancel(data: RefundInputModel): Promise<RefundResponseModel>
}

export class PaymentProviderFactory {
  public static GetForType (type: PaymentProviderType) {
    switch (type) {
      case PaymentProviderType.FISERV:
        return new FiservUtil()
      default:
        return new MitUtil()
    }
  }

  public static GetProvider () {
    const type = localStorage.getItem(
      'PINPAD_TYPE'
    ) as PaymentProviderType | null

    return PaymentProviderFactory.GetForType(type ?? PaymentProviderType.MIT)
  }
}

export const paymentUtil = PaymentProviderFactory.GetProvider()

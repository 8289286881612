/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { printerService } from '@/utils/printer/printer'
import {
  PaymentInputModel,
  PaymentResponseModel,
  RefundInputModel,
  RefundResponseModel
} from '../models/payment.model'
import { PaymentProvider } from '../payment-provider'

import { initConfig, pinpadService } from '@/utils/payment/vendor/pinpad'

export class MitUtil implements PaymentProvider {
  get initOnStart (): boolean {
    return !(initConfig?.ignoreOnStart ?? false)
  }

  get requiresInteractiveCancel (): boolean {
    return false
  }

  async init (): Promise<object> {
    if (!initConfig) {
      return Promise.reject('Falta realizar configuración de pinpad')
    }

    return pinpadService.init().then((data) => {
      return data as object
    })
  }

  executePayment (data: PaymentInputModel): Promise<PaymentResponseModel> {
    return pinpadService
      .readCard(data.amount + data.tip, data.reference, [])
      .then(async (response) => {
        console.log(response)

        const responseData = await pinpadService
          .sendToken(response)
          .then((response) => {
            console.log(response)

            return {
              transactionId: response.foliocpagos,
              reference: response.reference,
              authorization: response.auth,
              total: +response.amount,
              amount: data.amount,
              tip: data.tip,
              cardType: response.cc_type,
              date: response.date,
              ticket: pinpadService.verifyVoucher(response.voucher_cliente)
            }
          })

        if (responseData.ticket) {
          printerService
            .getConfig()
            .then((config) => {
              printerService.print(config, responseData.ticket ?? '')
            })
            .catch((err) => {
              console.log(err)
              // ignore
            })
        }

        return responseData
      })
  }

  cancel (data: RefundInputModel): Promise<RefundResponseModel> {
    return pinpadService
      .cancel(data.transactionId, data.authorization, data.amount + data.tip)
      .then((response: any) => {
        const voucher = pinpadService.verifyVoucher(response.VoucherCliente)

        return {
          transactionId: data.transactionId,
          reference: data.reference,
          authorization: data.authorization,
          amount: data.amount,

          ticket: voucher
        }
      })
  }
}
